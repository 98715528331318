import axios from 'axios';
import { SearchPlaceDTO } from './dto';
import { placesUrl } from '../../conf';


export interface PlacesSearchService {
  fetchResults: (params?: any) => Promise<SearchPlaceDTO>;
}

export const usePlacesSearchService = (): PlacesSearchService => {
  const fetchResults = async search => {
    const url = placesUrl;
    //console.log('Params get: ', search);
    const res = await axios.get(url, {
      params: {
        query: search,
      },
    });
    return res.data;
  };

  return { fetchResults };
};
